<template>
  <el-container style="height:84%">
    <el-main>
<!--        <div style="width: 1500px;margin:0 auto;margin-top: 20px">-->
<!--          <swiper :options="swiperOptions">-->
<!--            <swiper-slide style="height: 500px;text-align: center;width: 1300px" v-for="image in images" :key="image">-->
<!--                <div style="position: relative">-->
<!--                  <a :href="image.url"><img :src="image.imgUrl" height="500px" width="100%" style=""/></a>-->
<!--                  <img style="position: absolute;left: 0px" height="100px" src="../../../public/new_game.png" />-->
<!--                </div>-->
<!--            </swiper-slide>-->
<!--          </swiper>-->
<!--        </div>-->
        <div v-loading="loading" style="width: 1500px;margin:0 auto;margin-top: 20px;height: 80%;">
          <ul id="gameListUl" class="infinite-list"  style="overflow:auto;height: 92%">
            <li @click="goDetail(data.id)" v-for="(data,index) in datas" class="infinite-list-item">
              <div @mouseover="mouseoverImage(index)" @mouseleave="mouseleaveImage" style="padding: 10px; width: 230px;height: 128px;">
                <div style="overflow: hidden">
                  <img :class="[hoverIndex==index?'zoomed-in':'zoomed-out']" :src="data.bgUrl" width="100%" height="100%" >
                </div>
              </div>
              <div style="font-size: 14px;text-align: left;height: 20px;padding: 0 10px 0 10px;">
                <a @click="goDetail(data.id)" :title="data.name">{{data.name|ellipsis}}</a>
              </div>
              <div style="font-size: 12px;padding: 5px;float: left">
                <p style="margin-left: 5px"><i class="dot"></i>{{data.gameType==""?"其他游戏":data.gameType}}</p>
                <p style="margin-left: 5px;margin-top: 5px"><i class="el-icon-time"></i>{{data.updateTime}}</p>
              </div>
            </li>
          </ul>
        </div>
        <div style="width: 100%">
          <div class="block" style="margin: 0 auto;width: 720px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPageValue"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount">
            </el-pagination>
          </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      totalCount:0,
      currentPageValue:1,
      hoverIndex:-1,
      outClass: 'zoomed-out',
      isHovered: false,
      limit: 10,
      gameName: "",
      images: [
      ],
      swiperOptions: {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        loop: true,
      },
      datas:{},
      needGame:"",
      dialogFormVisible:false,
      loading:false
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return '';
      if (value.length > 15) {
        return value.slice(0, 15) + '...'
      }
      return value
    },
  },
  // 卸载
  beforeDestroy() {
  },
  created() {
    this.showGame(this.limit,1)
    this.initRollPicture()
  },
  mounted() {
  },
  methods: {
    goHome(){
      window.location.href='/'
    },
    handleSizeChange(size){
        this.showGame(size,1)
    },
    handleCurrentChange(currentPage){
        this.showGame(this.limit,currentPage)
    },
    mouseoverImage(index){
      this.hoverIndex = index;
    },
    mouseleaveImage(){
      this.hoverIndex = -1;
    },
    showGame(limit,page){
      this.loading = true;
      this.$axios.get(this.$gameApiurls.gameList+"?page="+page+"&limit="+limit)
        .then((res) => {
           var datas = res.data.list;
           this.totalCount = res.data.total;
           this.datas = datas;
           this.loading = false;
        });
    },goDetail(id){
        // 打开新窗口
        const newWindow = window.open('/gameDetail?gameId='+id);
        // 如果新窗口打开成功，则自动切换到该窗口
        if (newWindow) {
            newWindow.focus();
        }
    },initRollPicture(){
      this.$axios.get(this.$gameApiurls.gameList+"?page=1&limit=5&orderField=create_time&order=desc")
        .then((res) => {
          var datas = res.data.list
          for (let i = 0; i < datas.length ; i++) {
            let imgInfo = {"imgUrl":datas[i].bgUrl,"url":datas[i].url}
            this.images.push(imgInfo);
          }
        });
    }
  },
};
</script>

<style scoped>
body {
  height: 100vh;
  background-color: #f4fafe;
}

.zoomed-in {
  transform: scale(1.2); /* 放大1.2倍 */
  transition: transform 0.3s ease; /* 动画过渡效果 */
}

.zoomed-out {
  transform: scale(1); /* 放大1.2倍 */
  transition: transform 0.3s ease; /* 动画过渡效果 */
}

#headerUl>li{
  float: left;
  list-style: none;
}

a:hover{
  color:#1a284a;
  font-size: 15px;
}
.dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #fd721f;
  display: inline-block;
}
#gameListUl>li{
  float: left;
  list-style: none;
  height: 220px;
  width: 250px;
  border:1px solid #ccc;
  margin-top: 48px;
  margin-left: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 1px 4px 10px grey;
  background-color: white;
  opacity: 0.9;
}
.input-class .el-input__inner {
  text-align: center;
  border-radius: 30px;
  width: 380px;
  float: right;
  margin-right: 30%;
  margin-top:20px;
}
.el-main{
  padding: 0;
}

.glass-effect {
  height: 500px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}
/*.el-main{*/
/*  background: url("http://154.197.99.71:8082/file/bg02.jpg");*/
/*  !*background-size: contain;*!*/
/*}*/
</style>
